import React from 'react';
import { render } from 'storyblok-rich-text-react-renderer';
import { formatDate } from '../helpers';
import { GatsbyImage } from 'gatsby-plugin-image';

const Post = ({ title, published, image, alt, snippet, excerpt, content, downloads }) => {
	// Lots of the legacy data imported into Storyblok is a single string with all the HTML
	const isString = typeof content === 'string';
	return (
		<section>
			{/* <div className="float-right mb-4 md-mb-8">{snippet}</div> */}
			<h1 className="text-3xl font-alegreya mb-0">{title}</h1>
			<p className="text-sm text-gray-400 mb-4 md:mb-8">{formatDate(published)}</p>
			{excerpt && (<p className="italic text-gray-600 mb-4 md:mb-8">{excerpt}</p>) }
			{/* {image && (<GatsbyImage image={image} alt={alt} />) } */}
			{image && (<img src={image.filename} alt={image.alt} />) }
			<div className="text-lg my-4 md:my-8 text-gray-600">
				{isString ? (
					<div dangerouslySetInnerHTML={{ __html: content }} />
				) : (
					render(content)
				)}
			</div>
			{downloads && (
				<div>
					<h2 className="text-2xl font-alegreya font-medium text-gray-600 mb-0">Downloads</h2>
					{ downloads.map((item, key) => (
						<p key={key} className="text-gray-600 mt-2">Download "<a href={item.filename} rel="noreferrer" target="_blank" className="text-pink-700 font-medium underline md:no-underline md:hover:underline">{item.alt}</a>" ({item.filename.substr(item.filename.lastIndexOf('.') + 1).toUpperCase()})</p>
					))}
				</div>
			)}
		</section>
	);
};

export default Post;
