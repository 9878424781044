import { graphql, Link } from 'gatsby';
import React from 'react';
import CallToAction from '../components/CallToAction/CallToAction';
import Layout from '../components/layout';
import NewsList from '../components/NewsList/NewsList';
import Post from '../components/Post/Post';
import Seo from '../components/seo';
import { LINKS } from '../constants/Navigation';

const BlogPost = ({ data, pageContext, location }) => {
	const { story } = pageContext;
	const {
		title,
		content,
		excerpt,
		image,
		published,
		downloads,
	} = story;

	return (
		<Layout location={location}>
		<article>
			<div className="container md:mx-auto max-w-page py-4 md:py-8">
				<Post
					title={title}
					published={published}
					image={image}
					excerpt={excerpt}
					content={content}
					downloads={downloads}
					snippet={
						<Link to={LINKS.BLOG} className="uppercase shadow-sm transition-all duration-200 border border-1 border-pink-700 md:border-pink-900 md:hover:border-pink-700 rounded py-1 px-2 md:px-4 bg-pink-900 md:bg-pink-custom md:hover:bg-pink-900 text-gray-300 font-semibold md:hover:text-white">
							See all writing
						</Link>
					}
					url={location.href}
				/>
				<NewsList
					posts={data.posts.edges}
					title="More posts"
				/>
			</div>
		</article>
		<CallToAction/>
    </Layout>
	);
};

export const Head = ({ pageContext }) => {
	const { story } = pageContext;
	const {
		title,
		excerpt,
	} = story;
	return (
		<Seo title={title} description={excerpt} />
	)
}

export const query = graphql`
	query ($slug: String!){
		posts: allStoryblokEntry(
			filter: {
				field_component: { eq: "blogpost" }
				slug: { ne: $slug }			
			}
			limit: 3
			sort: { fields: field_published_string, order: DESC }
		) {
			totalCount
			edges {
				node {
					...blogpost
				}
			}
		}
	}
`;

export default BlogPost;
